import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => (
  <>
    <Skeleton width={120} height={30} className="mb-4" />
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <Skeleton width={100} height={22} className="mb-2" />
              <Skeleton height={40} />
            </div>
            <div className="form-group">
              <Skeleton width={100} height={22} className="mb-2" />
              <Skeleton height={40} />
            </div>
            <div className="form-group">
              <Skeleton width={100} height={22} className="mb-2" />
              <Skeleton height={40} />
              <Skeleton width={150} height={18} className="mt-2" />
            </div>
            <Skeleton width={120} height={40} />
          </div>
        </div>
      </div>
    </div>
  </>
);

export default LoadingSkeleton;
