import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'admin/orders', params });
const changeStatus = (payload) => configApi({ endpoint: 'admin/orders/change-status', method: 'put', payload });

const endpoint = {
  getAll,
  changeStatus,
};

export default endpoint;
