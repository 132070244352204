import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import StatusBadge from './StatusBadge';
import { Modal } from '../../../components/elements';
import { toRupiah } from '../../../helpers';

const TableRow = ({ label, value }) => (
  <tr>
    <td style={{ width: 150 }}>{label}</td>
    <td>
      {label === 'Status' ? (
        <div className="d-flex align-items-center">
          <span className="mr-1">:</span>
          <StatusBadge status={value} />
        </div>
      ) : `: ${value}`}
    </td>
  </tr>
);

TableRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const DetailModal = ({ show, onClose, order }) => (
  <Modal
    show={show}
    onClose={onClose}
    title={`Detail Order ${order?.invoice}`}
  >
    <table className="table table-borderless">
      <tbody>
        <TableRow label="Tanggal" value={`${moment(order?.date).format('DD MMM YYYY, HH:ss')} WIB`} />
        <TableRow label="Nama Penerima" value={order?.client_name} />
        <TableRow label="No WhatsApp" value={order?.client_whatsapp} />
        <TableRow label="Email" value={order?.client_email} />
        <TableRow label="Produk" value={order?.product_name} />
        <TableRow label="Harga" value={toRupiah(order?.price)} />
        <TableRow label="Status" value={order?.status} />
      </tbody>
    </table>
  </Modal>
);

DetailModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  order: PropTypes.shape({
    invoice: PropTypes.string,
    date: PropTypes.string,
    client_name: PropTypes.string,
    client_whatsapp: PropTypes.string,
    client_email: PropTypes.string,
    product_name: PropTypes.string,
    price: PropTypes.number,
    status: PropTypes.string,
  }),
};

DetailModal.defaultProps = {
  show: false,
  onClose: () => { },
  order: {
    invoice: '',
    date: '',
    client_name: '',
    client_whatsapp: '',
    client_email: '',
    product_name: '',
    price: 0,
    status: '',
  },
};

export default DetailModal;
