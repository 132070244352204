import CryptoJS from 'crypto-js';

const setSessionStorage = (key, value) => {
  const ciphertext = CryptoJS.AES.encrypt(value, process.env.REACT_APP_CRYPTO_SECRET_KEY);
  sessionStorage.setItem(key, ciphertext);
};

const getSessionStorage = (key) => {
  const storageItem = sessionStorage.getItem(key);
  if (!storageItem) return '';
  const bytes = CryptoJS.AES.decrypt(storageItem, process.env.REACT_APP_CRYPTO_SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export {
  setSessionStorage,
  getSessionStorage,
};
