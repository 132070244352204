import React from 'react';
import { Link } from 'react-router-dom';

import { MENU_LIST } from '../../../../helpers/enums';

const SidebarMenu = () => (
  <div className="vertical-menu">
    <div className="navbar-brand-box">
      <a href="/" className="logo">
        <span className="logo-sm">
          <img
            src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-icon.png`}
            alt="logo"
            height={30}
          />
        </span>
        <span className="logo-lg">
          <img
            src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`}
            alt="logo"
            height={30}
          />
        </span>
      </a>
    </div>
    <button
      aria-label="menu"
      type="button"
      className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
    >
      <i className="fa fa-fw fa-bars" />
    </button>
    <div data-simplebar="" className="sidebar-menu-scroll">
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          {MENU_LIST.map((menu) => (
            <li key={menu.name}>
              <Link to={menu.link} className="waves-effect">
                <i className={`uil-${menu.icon}`} />
                <span>{menu.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default SidebarMenu;
