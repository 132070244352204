import React from 'react';
import PropTypes from 'prop-types';

const AffiliateInfo = ({
  name, email, whatsapp, bankName, bankAccountNumber, bankAccountName,
}) => (
  <div className="card">
    <div className="card-body">
      <table className="table table-sm table-borderless mb-0">
        <tr>
          <td>Nama</td>
          <td>{`: ${name}`}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{`: ${email}`}</td>
        </tr>
        <tr>
          <td style={{ width: 120 }}>No Whatsapp</td>
          <td>{`: ${whatsapp}`}</td>
        </tr>
        <tr>
          <td>No Rekening</td>
          <td>{`: ${bankName && bankAccountName && bankAccountNumber ? `${bankName} ${bankAccountNumber} as ${bankAccountName}` : '-'}`}</td>
        </tr>
      </table>
    </div>
  </div>
);

AffiliateInfo.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  whatsapp: PropTypes.string.isRequired,
  bankName: PropTypes.string.isRequired,
  bankAccountNumber: PropTypes.string.isRequired,
  bankAccountName: PropTypes.string.isRequired,
};

export default AffiliateInfo;
