import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => (
  <>
    <div className="row mt-4">
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={150} />
        </div>
      </div>
    </div>
    <Skeleton width={200} height={40} />
  </>
);

export default LoadingSkeleton;
