import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import PageTopbar from './components/PageTopbar';
import SidebarMenu from './components/SidebarMenu';

const DashboardLayout = ({ children }) => {
  const { pathname } = useLocation();
  function handleMenuToggle(event) {
    event.preventDefault();
    const body = document.querySelector('body');
    body.classList.toggle('sidebar-enable');
    if (window.innerWidth >= 992) {
      body.classList.toggle('vertical-collpsed');
    } else {
      body.classList.remove('vertical-collpsed');
    }
  }

  useEffect(() => {
    document.querySelectorAll('.vertical-menu-btn').forEach((btn) => {
      btn.addEventListener('click', handleMenuToggle);
    });

    function activateMenu() {
      const pageUrl = window.location.href.split(/[?#]/)[0];
      const sidebarLinks = document.querySelectorAll('#sidebar-menu a');
      sidebarLinks.forEach((link) => {
        if (pageUrl.includes(link.href)) {
          link.classList.add('active');
          link.parentNode.classList.add('mm-active');
        } else {
          link.classList.remove('active');
          link.parentNode.classList.remove('mm-active');
        }
      });
    }

    activateMenu();

    return () => {
      document.querySelectorAll('.vertical-menu-btn').forEach((btn) => {
        btn.removeEventListener('click', handleMenuToggle);
      });
    };
  }, [pathname]);

  return (
    <div id="layout-wrapper">
      <PageTopbar />
      <SidebarMenu />
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid" style={{ minHeight: 500 }}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
