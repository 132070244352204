import configApi from '../configApi';

const uploadFile = (payload) => configApi({ endpoint: 'upload', method: 'post', payload });
const deleteFile = (payload) => configApi({ endpoint: 'upload', method: 'delete', payload });

const endpoint = {
  uploadFile,
  deleteFile,
};

export default endpoint;
