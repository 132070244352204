import React from 'react';
import PropTypes from 'prop-types';

const InputField = ({
  id,
  name,
  label,
  type,
  placeholder,
  helperText,
  readOnly,
  error,
  classNameContainer,
  value,
  onChange,
  onBlur,
}) => (
  <div className={`form-group ${classNameContainer}`}>
    {label && <label htmlFor={id}>{label}</label>}
    <input
      id={id}
      type={type}
      name={name}
      className={`${type === 'file' ? 'form-control-file' : 'form-control'} ${error ? 'is-invalid' : ''}`}
      placeholder={placeholder}
      readOnly={readOnly}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    />
    {helperText && <small className="text-muted mt-1 d-block">{helperText}</small>}
    {error && <small className="text-danger mt-1 d-block">{error}</small>}
  </div>
);

InputField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'number', 'file', 'color', 'date', 'password']),
  classNameContainer: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.string,
  readOnly: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

InputField.defaultProps = {
  id: '',
  name: '',
  label: '',
  type: 'text',
  classNameContainer: '',
  placeholder: '',
  readOnly: false,
  helperText: '',
  error: '',
  value: '',
  onChange: () => {},
  onBlur: () => {},
};

export default InputField;
