import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';

import LoadingSkeleton from './LoadingSkeleto';
import authEndpoint from '../../../config/service/endpoint/auth';
import { Button, InputField } from '../../../components/core';

const EditProfilePage = () => {
  const { data, isFetching } = useQuery('profile', authEndpoint.getProfile, {
    onError: (error) => toast.error(error.message),
  });

  const { mutateAsync, isLoading } = useMutation(authEndpoint.updateProfile, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Profil berhasil diubah.'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      name: yup.string().trim().required('Nama harus diisi').max(50, 'Nama maksimal 50 karakter'),
      email: yup.string().trim().email('Email tidak valid').required('Email harus diisi')
        .max(50, 'Email maksimal 50 karakter'),
      password: yup.string().trim().min(5, 'Password minimal 5 karakter'),
    }),
    onSubmit: async (values) => {
      await mutateAsync({
        full_name: values.name,
        email: values.email,
        password: values.password,
      });
      formik.resetForm();
      formik.setValues({
        name: values.name,
        email: values.email,
        password: '',
      });
    },
  });

  useEffect(() => {
    if (data?.data) {
      formik.setValues({
        name: data.data.full_name,
        email: data.data.email,
        password: '',
      });
    }
  }, [data]);

  if (isFetching) return <LoadingSkeleton />;

  return (
    <>
      <h4 className="mb-4">Edit Profil</h4>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              <FormikProvider value={formik}>
                <form onSubmit={formik.handleSubmit}>
                  <InputField
                    name="name"
                    id="name"
                    label="Nama"
                    error={formik.touched.name && formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputField
                    name="email"
                    id="email"
                    label="Email"
                    error={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <InputField
                    name="password"
                    id="password"
                    label="Password"
                    helperText="Kosongi jika tidak ingin ubah password"
                    error={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <Button isLoading={isLoading} type="submit" className="px-3">SIMPAN</Button>
                </form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProfilePage;
