import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const menusData = [
  {
    name: 'configuration',
    label: 'Konfigurasi',
    url: '/pengaturan/notifikasi',
  },
  {
    name: 'bill',
    label: 'Tagihan',
    url: '/pengaturan/notifikasi/tagihan',
  },
  {
    name: 'finish',
    label: 'Selesai',
    url: '/pengaturan/notifikasi/selesai',
  },
  {
    name: 'cancel',
    label: 'Batal',
    url: '/pengaturan/notifikasi/batal',
  },
  {
    name: 'expired',
    label: 'Kadaluarsa',
    url: '/pengaturan/notifikasi/kadaluarsa',
  },
  {
    name: 'affiliate',
    label: 'Affiliate',
    url: '/pengaturan/notifikasi/affiliate',
  },
  {
    name: 'commission',
    label: 'Komisi',
    url: '/pengaturan/notifikasi/komisi',
  },
  {
    name: 'affiliationRegistration',
    label: 'Daftar Affiliate',
    url: '/pengaturan/notifikasi/daftar-affiliate',
  },
];

const NotifMenu = ({ tab }) => (
  <p className="mt-2">
    {menusData.map((menu, index) => {
      const submenuUrl = menu.url.split('/');
      const isActive = tab === menu.name || tab === submenuUrl[3];
      return (
        <Fragment key={menu.name}>
          <Link className={isActive ? 'text-success' : 'text-secondary'} to={menu.url}>{menu.label}</Link>
          {index + 1 < menusData.length && <span className="mx-2">|</span>}
        </Fragment>
      );
    })}
  </p>
);

NotifMenu.propTypes = {
  tab: PropTypes.string,
};

NotifMenu.defaultProps = {
  tab: '',
};

export default NotifMenu;
