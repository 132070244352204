import React from 'react';
import { Outlet } from 'react-router-dom';

import { getSessionStorage } from '../../helpers';
import { DashboardLayout } from '../../components/layouts';

const ProtectedRoute = () => {
  const tokenStorage = getSessionStorage('token');
  if (tokenStorage) {
    return (
      <DashboardLayout>
        <Outlet />
      </DashboardLayout>
    );
  }
  window.location.href = '/';
  return null;
};

export default ProtectedRoute;
