import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { useQuery } from 'react-query';

import LoadingOrderSummary from './LoadingOrderSummary';
import dashboardEndpoint from '../../../config/service/endpoint/dashboard';
import { salesOverviewCard } from '../utils';
import { toRupiah } from '../../../helpers';

const OrderSummary = ({ filter }) => {
  const { data, isFetching, refetch } = useQuery('order-summary', () => dashboardEndpoint.getOrderSummary({ filter: filter === 'all' ? '' : filter }), {
    onError: (error) => toast.error(error.message),
  });

  useEffect(() => {
    refetch();
  }, [filter]);

  if (isFetching) return <LoadingOrderSummary />;

  const summaryData = data?.data;

  return (
    <div className="row">
      {salesOverviewCard.map((overviewItem) => (
        <div key={overviewItem.accessor} className="col-md-6 col-xl-3">
          <div className="card">
            <div className="card-body d-flex align-items-center">
              <img src={overviewItem.image} alt={overviewItem.title} style={{ width: 40 }} />
              <div className="ml-3">
                <p className="text-muted mb-1">{overviewItem.title}</p>
                <h4 className="mb-0">{overviewItem.accessor === 'revenue' ? toRupiah(summaryData[overviewItem.accessor]) : summaryData[overviewItem.accessor]}</h4>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

OrderSummary.propTypes = {
  filter: PropTypes.string,
};

OrderSummary.defaultProps = {
  filter: 'all',
};

export default OrderSummary;
