import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import AffiliateInfo from './components/Detail/AffiliateInfo';
import PaymentHistory from './components/Detail/PaymentHistory';
import PaymentModal from './components/PaymentModal';
import commissionEndpoint from '../../config/service/endpoint/commission';

const DetailCommissionPage = () => {
  const [openedPayModal, setOpenedPayModal] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const { data, isFetching, refetch } = useQuery(`commissionDetail${params.id}`, () => commissionEndpoint.getDetail(params.id), {
    retry: false,
    onError: (error) => {
      toast.error(error.message);
      navigate('/komisi');
    },
  });

  const commission = data?.data;

  return (
    <>
      <h4 className="mb-4">Komisi | Bayar Komisi</h4>
      {!isFetching && (
        <AffiliateInfo
          name={commission?.client.name}
          email={commission?.client.email}
          whatsapp={commission?.client.whatsapp}
          bankName={commission?.client.bank_name}
          bankAccountNumber={commission?.client.bank_account_number}
          bankAccountName={commission?.client.bank_account_name}
        />
      )}
      <PaymentHistory
        isLoading={isFetching}
        commissions={commission?.commissions}
        onOpenPayment={() => setOpenedPayModal(true)}
      />
      <PaymentModal
        clientId={params.id}
        unpaid={commission?.client?.unpaid_commission}
        bankName={commission?.client.bank_name}
        bankAccountNumber={commission?.client.bank_account_number}
        bankAccountName={commission?.client.bank_account_name}
        show={openedPayModal}
        onClose={() => setOpenedPayModal(false)}
        onRefetch={refetch}
      />
    </>
  );
};

export default DetailCommissionPage;
