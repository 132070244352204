import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';

import templateEndpoint from '../../../config/service/endpoint/template';
import { Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';

const AddFormModal = ({ show, onClose, onRefetch }) => {
  const { mutateAsync, isLoading } = useMutation(templateEndpoint.createTemplate, {
    onError: (error) => toast.error(error.message),
  });

  const formik = useFormik({
    initialValues: { category: '' },
    validationSchema: yup.object({
      category: yup.string().trim().required('Nama kategori harus diisi.').max(50, 'Maksimal 50 karakter'),
    }),
    onSubmit: async (values) => {
      await mutateAsync({ category: values.category });
      formik.resetForm();
      toast.success('Berhasil menambah template.');
      onRefetch();
      onClose();
    },
  });

  return (
    <Modal
      show={show}
      onClose={onClose}
      title="Tambah Template"
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <InputField
            id="category"
            name="category"
            label="Nama Kategori"
            value={formik.values.category}
            error={formik.touched.category && formik.errors.category}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <Button type="submit" className="px-4" isLoading={isLoading}>Tambah</Button>
        </form>
      </FormikProvider>
    </Modal>
  );
};

AddFormModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
};

AddFormModal.defaultProps = {
  show: false,
  onClose: () => {},
  onRefetch: () => {},
};

export default AddFormModal;
