import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { FieldArray, FormikProvider, useFormik } from 'formik';

import templateEndpoint from '../../config/service/endpoint/template';
import EditLoadingSkeleton from './components/EditLoadingSkeleton';
import { Button, Textarea } from '../../components/core';

const tabList = [
  { name: 'greeting', label: 'Greeting' },
  { name: 'welcome', label: 'Welcome' },
  { name: 'follow_up_1', label: 'Follow Up 1' },
  { name: 'follow_up_2', label: 'Follow Up 2' },
  { name: 'follow_up_3', label: 'Follow Up 3' },
  { name: 'complete', label: 'Complete' },
  { name: 'cancel', label: 'Cancel' },
  { name: 'up_sell', label: 'Up Sell' },
  { name: 'cross_sell', label: 'Cross Sell' },
  { name: 'rules_cod', label: 'Rules COD' },
  { name: 'form_order', label: 'Form Order' },
  { name: 'account_info', label: 'Info Rekening' },
];

const convertType = (type) => {
  switch (type) {
    case 'follow_up_1':
      return 'followUp1';
    case 'follow_up_2':
      return 'followUp2';
    case 'follow_up_3':
      return 'followUp3';
    case 'up_sell':
      return 'upSell';
    case 'cross_sell':
      return 'crossSell';
    case 'rules_cod':
      return 'rulesCOD';
    case 'form_order':
      return 'formOrder';
    case 'account_info':
      return 'accountInfo';
    default:
      return type;
  }
};

const EditTemplatePage = () => {
  const [tab, setTab] = useState('greeting');
  const params = useParams();
  const navigate = useNavigate();

  const { isFetching, data, refetch } = useQuery('template', () => templateEndpoint.getById(params.id), {
    retry: false,
    onError: (error) => {
      toast.error(error.message);
      navigate('/template');
    },
  });

  const { mutate: updateMutate, isLoading: isLoadingUpdate } = useMutation(templateEndpoint.updateTemplate, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menyimpan template');
      refetch();
    },
  });

  const formik = useFormik({
    initialValues: { content: [] },
    onSubmit: (values) => {
      const payload = {
        type: convertType(tab),
        content: values.content,
      };
      updateMutate({ id: params.id, payload });
    },
  });

  useEffect(() => {
    if (data?.data[tab]) {
      formik.setFieldValue('content', data.data[tab]);
    }
  }, [data, tab]);

  return (
    <>
      <h4 className="mb-4">Template | Edit</h4>
      <div className="card">
        <div className="card-body">
          {isFetching ? <EditLoadingSkeleton /> : (
            <div className="row">
              <div className="col-md-3">
                {tabList.map((_tab) => (
                  <Button
                    key={_tab.name}
                    variant={_tab.name === tab ? 'primary' : 'outline-secondary'}
                    className="w-100 mb-3"
                    onClick={() => setTab(_tab.name)}
                  >
                    {_tab.label}
                  </Button>
                ))}
              </div>
              <div className="col-md-9">
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit}>
                    <FieldArray
                      name="content"
                      render={(arrayHelpers) => (
                        <>
                          {formik.values.content.map((_, index) => (
                            <Textarea
                              // eslint-disable-next-line react/no-array-index-key
                              key={index}
                              name={`content.${index}`}
                              value={formik.values.content[index]}
                              onChange={formik.handleChange}
                            />
                          ))}
                          <Button onClick={() => arrayHelpers.push('')} variant="outline-primary" className="px-4">Tambah</Button>
                        </>
                      )}
                    />
                    <div className="d-flex mt-4">
                      <Button type="submit" isLoading={isLoadingUpdate} variant="success" className="px-4 mr-2">SIMPAN</Button>
                      <Link to="/template">
                        <Button variant="secondary" className="px-4">KEMBALI</Button>
                      </Link>
                    </div>
                  </form>
                </FormikProvider>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default EditTemplatePage;
