import React from 'react';

import Form from './components/Form';

const AddProductPage = () => (
  <>
    <h4 className="mb-4">Tambah Produk</h4>
    <div className="card">
      <div className="card-body">
        <Form />
      </div>
    </div>
  </>
);

export default AddProductPage;
