import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Badge } from 'react-bootstrap';

import { Button } from '../../../../components/core';
import { Dropdown, Table } from '../../../../components/elements';
import { toRupiah } from '../../../../helpers';

const PaymentHistory = ({ isLoading, commissions, onOpenPayment }) => (
  <div className="card">
    <div className="card-body">
      <div className="d-flex justify-content-between mb-4">
        <Button variant="success" onClick={onOpenPayment}>BAYAR</Button>
        <Dropdown variant="outline-primary" label="Filter" />
      </div>
      <Table
        totalData={commissions.length}
        perPage={commissions.length}
        isLoading={isLoading}
        headRender={(
          <tr>
            <th>Order ID</th>
            <th>Nama Pembeli</th>
            <th>Tanggal</th>
            <th>Produk</th>
            <th>Status</th>
            <th>Komisi</th>
          </tr>
        )}
        bodyRender={commissions.map((item) => (
          <tr key={item.id}>
            <td>{item.invoice}</td>
            <td>{item.client_name}</td>
            <td>{moment(item.date).format('DD MMM YYYY, HH:mm WIB')}</td>
            <td>{item.product_name}</td>
            <td>
              <Badge className="text-white" bg={item.status === 'paid' ? 'success' : 'warning'}>
                {item.status === 'paid' ? 'Sudah Dibayar' : 'Belum Dibayar'}
              </Badge>
            </td>
            <td>{toRupiah(item.amount)}</td>
          </tr>
        ))}
      />
    </div>
  </div>
);

PaymentHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  commissions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    invoice: PropTypes.string,
    client_name: PropTypes.string,
    date: PropTypes.string,
    product_name: PropTypes.string,
    status: PropTypes.string,
    amount: PropTypes.number,
  })),
  onOpenPayment: PropTypes.func,
};

PaymentHistory.defaultProps = {
  commissions: [],
  onOpenPayment: () => {},
};

export default PaymentHistory;
