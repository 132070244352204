import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';
import {
  AddProductPage,
  CommissionPage,
  DashboardPage,
  DetailCommissionPage,
  EditProductPage,
  EditProfilePage,
  EditTemplatePage,
  Error404,
  LoginPage,
  ProductPage,
  SalesPage,
  SettingAccountPage,
  SettingNotifConfigPage,
  SettingNotifContentPage,
  SettingPaymentPage,
  TemplatePage,
  UserPage,
} from '../../views';

const router = createBrowserRouter([
  {
    element: <PublicRoute />,
    children: [
      {
        path: '/',
        element: <LoginPage />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: '/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/penjualan',
        element: <SalesPage />,
      },
      {
        path: '/produk',
        element: <ProductPage />,
      },
      {
        path: '/produk/tambah',
        element: <AddProductPage />,
      },
      {
        path: '/produk/:id',
        element: <EditProductPage />,
      },
      {
        path: '/template',
        element: <TemplatePage />,
      },
      {
        path: '/template/:id',
        element: <EditTemplatePage />,
      },
      {
        path: '/komisi',
        element: <CommissionPage />,
      },
      {
        path: '/komisi/:id',
        element: <DetailCommissionPage />,
      },
      {
        path: '/user',
        element: <UserPage />,
      },
      {
        path: '/pengaturan',
        element: <Navigate to="/pengaturan/akun" replace />,
      },
      {
        path: '/pengaturan/akun',
        element: <SettingAccountPage />,
      },
      {
        path: '/pengaturan/pembayaran',
        element: <SettingPaymentPage />,
      },
      {
        path: '/pengaturan/notifikasi',
        element: <SettingNotifConfigPage />,
      },
      {
        path: '/pengaturan/notifikasi/:type',
        element: <SettingNotifContentPage />,
      },
      {
        path: '/akun/edit-profil',
        element: <EditProfilePage />,
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

export default router;
