import configApi from '../configApi';

const getOrderSummary = (params) => configApi({ endpoint: 'admin/dashboard/order-summary', params });
const getChart = () => configApi({ endpoint: 'admin/dashboard/chart' });

const endpoint = {
  getOrderSummary,
  getChart,
};

export default endpoint;
