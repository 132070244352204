import configApi from '../configApi';

const setting = () => configApi({ endpoint: 'admin/setting' });
const notifContent = (type) => configApi({ endpoint: `admin/setting/notification/${type}` });
const updateSetting = (payload) => configApi({ endpoint: 'admin/setting', method: 'put', payload });
const updateNotif = (payload) => configApi({ endpoint: 'admin/setting/notification', method: 'put', payload });

const endpoint = {
  setting,
  notifContent,
  updateSetting,
  updateNotif,
};

export default endpoint;
