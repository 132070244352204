import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';

import Menu from '../../components/Menu';
import NotifMenu from '../Menu';
import settingEndpoint from '../../../../config/service/endpoint/setting';
import { Button, InputField } from '../../../../components/core';
import LoadingSkeleton from './LoadingSkeleton';

const SettingNotifConfigPage = () => {
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(settingEndpoint.updateSetting, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Berhasil menyimpan pengaturan.'),
  });

  const formik = useFormik({
    initialValues: {
      config_email_username: '',
      config_email_password: '',
      config_email_smtp_host: '',
      config_email_port: '',
    },
    validationSchema: yup.object({
      config_email_username: yup.string().trim().required('Username email harus diisi.').max(50, 'Maksimal 50 karakter.'),
      config_email_password: yup.string().trim().required('Password email harus diisi.').max(50, 'Maksimal 50 karakter.'),
      config_email_smtp_host: yup.string().trim().required('SMTP host harus diisi.').max(50, 'Maksimal 50 karakter.'),
      config_email_port: yup.number().typeError('Port harus berupa angka.').required('Port harus diisi.').min(1, 'Masukkan port yang valid.'),
    }),
    onSubmit: (values) => {
      mutateUpdate({
        config_email_username: values.config_email_username,
        config_email_password: values.config_email_password,
        config_email_smtp_host: values.config_email_smtp_host,
        config_email_port: values.config_email_port,
      });
    },
  });

  const { isFetching: isLoadingFetch } = useQuery('setting', settingEndpoint.setting, {
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const { data: newData } = data;
      formik.setFieldValue('config_email_username', newData.config_email_username);
      formik.setFieldValue('config_email_password', newData.config_email_password);
      formik.setFieldValue('config_email_smtp_host', newData.config_email_smtp_host);
      formik.setFieldValue('config_email_port', newData.config_email_port);
    },
  });

  return (
    <>
      <h4 className="mb-4">Pengaturan</h4>
      <div className="card">
        <div className="card-body">
          <Menu tab="notification" />
          <NotifMenu tab="configuration" />
          {isLoadingFetch ? (
            <LoadingSkeleton />
          ) : (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} className="mt-4">
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      id="config_email_username"
                      name="config_email_username"
                      label="Username Email"
                      value={formik.values.config_email_username}
                      error={formik.touched.config_email_username && formik.errors.config_email_username}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      id="config_email_password"
                      name="config_email_password"
                      label="Password Email"
                      type="password"
                      value={formik.values.config_email_password}
                      error={formik.touched.config_email_password && formik.errors.config_email_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      id="config_email_smtp_host"
                      name="config_email_smtp_host"
                      label="SMTP Host"
                      value={formik.values.config_email_smtp_host}
                      error={formik.touched.config_email_smtp_host && formik.errors.config_email_smtp_host}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputField
                      id="config_email_port"
                      name="config_email_port"
                      label="Port"
                      value={formik.values.config_email_port}
                      error={formik.touched.config_email_port && formik.errors.config_email_port}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </div>
                <Button
                  type="submit"
                  variant="success"
                  className="px-4"
                  isLoading={isLoadingUpdate}
                >
                  SIMPAN PENGATURAN
                </Button>
              </form>
            </FormikProvider>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingNotifConfigPage;
