import React from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import productEndpoint from '../../config/service/endpoint/product';
import Form from './components/Form';
import FormLoadingSkeleton from './components/FormLoadingSkeleton';

const EditProductPage = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { isFetching, data } = useQuery('template', () => productEndpoint.getById(params.id), {
    retry: false,
    onError: (error) => {
      toast.error(error.message);
      navigate('/produk');
    },
  });

  return (
    <>
      <h4 className="mb-4">Edit Produk</h4>
      <div className="card">
        <div className="card-body">
          {isFetching ? <FormLoadingSkeleton /> : <Form initialData={data.data} />}
        </div>
      </div>
    </>
  );
};

export default EditProductPage;
