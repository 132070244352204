import React, { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';
import {
  BtnBold, BtnItalic, BtnStrikeThrough, Editor, EditorProvider, Toolbar,
} from 'react-simple-wysiwyg';

import Menu from '../../components/Menu';
import NotifMenu from '../Menu';
import LoadingSkeleton from './LoadingSkeleton';
import settingEndpoint from '../../../../config/service/endpoint/setting';
import { Button, InputField } from '../../../../components/core';

const SettingNotifContentPage = () => {
  const params = useParams();

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(settingEndpoint.updateNotif, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Berhasil menyimpan pengaturan.'),
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      content: '',
    },
    validationSchema: yup.object({
      title: yup.string().trim().required('Judul harus diisi.').max(150, 'Maksimal 150 karakter.'),
      content: yup.string().trim().required('Konten harus diisi.'),
    }),
    onSubmit: (values) => {
      mutateUpdate({
        title: values.title,
        type: params.type,
        content: values.content,
      });
    },
  });

  const { isFetching: isLoadingFetch, refetch } = useQuery('notifContent', () => settingEndpoint.notifContent(params.type), {
    onError: (error) => {
      toast.error(error.message);
      formik.setFieldValue('title', '');
      formik.setFieldValue('content', '');
    },
    onSuccess: (data) => {
      const { data: newData } = data;
      formik.setFieldValue('title', newData.title);
      formik.setFieldValue('content', newData.content);
    },
  });

  useEffect(() => {
    refetch();
  }, [params]);

  return (
    <>
      <h4 className="mb-4">Pengaturan</h4>
      <div className="card">
        <div className="card-body">
          <Menu tab="notification" />
          <NotifMenu tab={params.type} />
          {isLoadingFetch ? (
            <LoadingSkeleton />
          ) : (
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit} className="mt-4">
                <div className="row">
                  <div className="col-md-6">
                    <InputField
                      id="title"
                      name="title"
                      label="Judul"
                      value={formik.values.title}
                      error={formik.touched.title && formik.errors.title}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="form-group">
                      <label>Konten</label>
                      <EditorProvider>
                        <Editor
                          name="content"
                          value={formik.values.content}
                          containerProps={{ style: { minHeight: 200 } }}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <Toolbar>
                            <BtnBold />
                            <BtnItalic />
                            <BtnStrikeThrough />
                          </Toolbar>
                        </Editor>
                      </EditorProvider>
                      {formik.touched.content && formik.errors.content && (
                        <small className="text-danger">{formik.errors.content}</small>
                      )}
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  variant="success"
                  className="px-4"
                  isLoading={isLoadingUpdate}
                >
                  SIMPAN PENGATURAN
                </Button>
              </form>
            </FormikProvider>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingNotifContentPage;
