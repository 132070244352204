import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown as DropdownBootstrap } from 'react-bootstrap';

const Dropdown = ({
  label, variant, data, onChoose,
}) => (
  <DropdownBootstrap>
    <DropdownBootstrap.Toggle variant={variant} className="px-4">
      {label}
    </DropdownBootstrap.Toggle>
    <DropdownBootstrap.Menu>
      {data.map((item) => (
        <DropdownBootstrap.Item
          key={item.value}
          onClick={() => onChoose(item.value, item)}
        >
          {item.label}
        </DropdownBootstrap.Item>
      ))}
    </DropdownBootstrap.Menu>
  </DropdownBootstrap>
);

Dropdown.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary', 'light', 'success', 'info', 'warning', 'danger', 'dark', 'link', 'secondary', 'outline-primary', 'outline-secondary',
  ]),
  onChoose: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  })),
};

Dropdown.defaultProps = {
  label: '',
  variant: 'primary',
  onChoose: () => {},
  data: [],
};

export default Dropdown;
