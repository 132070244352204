import axios from 'axios';

import { getSessionStorage } from '../../helpers';

const configApi = async ({
  endpoint, method = 'get', payload = null, params = null,
}) => {
  const headers = {
    'Content-Type': payload instanceof FormData ? 'multipart/form-data' : 'application/json',
  };
  const tokenStorage = getSessionStorage('token');
  if (tokenStorage) {
    headers.token = `Bearer ${tokenStorage}`;
  }
  const serviceURL = process.env.REACT_APP_SERVICE_URL_API;
  const url = `${serviceURL}${endpoint}`;
  const options = {
    url,
    method,
    headers,
  };
  if (payload) {
    options.data = payload;
  } else if (params) {
    options.params = params;
  }
  try {
    const response = await axios(options);
    if (!response.data.success) throw new Error(response.data.message);
    return Promise.resolve(response.data);
  } catch (error) {
    if (error.response.status === 401) {
      sessionStorage.removeItem('token');
      window.location.href = '/';
    }
    return Promise.reject(error.response.data);
  }
};

export default configApi;
