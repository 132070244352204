import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation } from 'react-query';
import { Alert } from 'react-bootstrap';

import authEndpoint from '../../config/service/endpoint/auth';
import { Button, InputField } from '../../components/core';
import { setSessionStorage } from '../../helpers';

const LoginPage = () => {
  const handleSuccessLogin = (data) => {
    toast.success('Berhasil login.');
    setSessionStorage('token', data.data.token);
    setSessionStorage('user', JSON.stringify({
      name: data.data.full_name,
    }));
    window.location.href = '/dashboard';
  };

  const { mutate, error, isLoading } = useMutation(authEndpoint.login, {
    onSuccess: handleSuccessLogin,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      email: yup.string().required('Email harus diisi.'),
      password: yup.string().required('Password harus diisi.'),
    }),
    onSubmit: (values) => {
      mutate({
        email: values.email,
        password: values.password,
      });
    },
  });

  return (
    <div className="account-pages my-5 pt-sm-5">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8 col-lg-6 col-xl-5">
            <div className="card">
              <div className="card-body p-4">
                <div className="text-center mt-2">
                  <img src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`} width="200" alt="Logo" />
                </div>
                <div className="p-2 mt-4">
                  {error && (
                    <Alert variant="danger">
                      {error.message}
                    </Alert>
                  )}
                  <FormikProvider value={formik}>
                    <form onSubmit={formik.handleSubmit}>
                      <InputField
                        name="email"
                        id="email"
                        label="Email"
                        placeholder="Masukkan Email"
                        error={formik.touched.email && formik.errors.email}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <InputField
                        name="password"
                        id="password"
                        label="Password"
                        type="password"
                        placeholder="Masukkan Password"
                        error={formik.touched.password && formik.errors.password}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <Button
                        type="submit"
                        className="w-100 mt-3"
                        size="lg"
                        isLoading={isLoading}
                      >
                        Login
                      </Button>
                    </form>
                  </FormikProvider>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p>© Catatin.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
