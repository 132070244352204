import React from 'react';
import Skeleton from 'react-loading-skeleton';

const CardItem = () => (
  <div className="col-md-6 col-xl-3">
    <div className="card">
      <div className="card-body d-flex align-items-center">
        <Skeleton width={50} height={50} />
        <div className="ml-3">
          <Skeleton width={100} height={18} />
          <Skeleton width={60} height={25} className="mt-2" />
        </div>
      </div>
    </div>
  </div>
);

const LoadingOrderSummary = () => (
  <div className="row">
    <CardItem />
    <CardItem />
    <CardItem />
    <CardItem />
  </div>
);

export default LoadingOrderSummary;
