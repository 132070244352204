export const STATUS_ORDER = {
  NEW: 'new',
  UNPAID: 'unpaid',
  PAID: 'paid',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
  COMPLETED: 'completed',
};

export const MENU_LIST = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    icon: 'dashboard',
    link: '/dashboard',
  },
  {
    name: 'order',
    label: 'Penjualan',
    icon: 'shopping-basket',
    link: '/penjualan',
  },
  {
    name: 'product',
    label: 'Produk',
    icon: 'box',
    link: '/produk',
  },
  {
    name: 'template',
    label: 'Template',
    icon: 'clipboard',
    link: '/template',
  },
  {
    name: 'commission',
    label: 'Komisi',
    icon: 'wallet',
    link: '/komisi',
  },
  {
    name: 'user',
    label: 'User',
    icon: 'user-circle',
    link: '/user',
  },
  {
    name: 'setting-account',
    label: 'Pengaturan',
    icon: 'setting',
    link: '/pengaturan/akun',
  },
];

export const TOP_BAR_MENU = [
  {
    name: 'edit-profile',
    label: 'Edit Profil',
    icon: 'lock-alt',
    link: '/akun/edit-profil',
  },
];
