import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({
  id, name, label, value, checked, onChange,
}) => (
  <div
    className="custom-control custom-radio"
    role="button"
    tabIndex={0}
    onClick={onChange}
    onKeyDown={onChange}
  >
    <input className="custom-control-input" type="radio" name={name} id={id} checked={checked} value={value} />
    <label className="custom-control-label" htmlFor={id}>
      {label}
    </label>
  </div>
);

Radio.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  id: '',
  name: '',
  label: '',
  value: '',
  checked: false,
  onChange: () => {},
};

export default Radio;
