import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'admin/products', params });
const getById = (id) => configApi({ endpoint: `admin/products/${id}` });
const insertProduct = (payload) => configApi({ endpoint: 'admin/products', method: 'post', payload });
const updateProduct = (payload) => configApi({ endpoint: `admin/products/${payload.id}`, method: 'put', payload });
const deleteProduct = (id) => configApi({ endpoint: `admin/products/${id}`, method: 'delete' });

const endpoint = {
  getAll,
  getById,
  insertProduct,
  updateProduct,
  deleteProduct,
};

export default endpoint;
