import React from 'react';
import PropTypes from 'prop-types';

const Select = ({
  id, name, label, data, placeholder, helperText, classNameContainer, className, value, error, onChange, onBlur,
}) => (
  <div className={`form-group ${classNameContainer}`}>
    {label && <label htmlFor={id}>{label}</label>}
    <select
      id={id}
      name={name}
      className={`form-control ${error ? 'is-invalid' : ''} ${className}`}
      onChange={onChange}
      onBlur={onBlur}
    >
      {placeholder && <option selected disabled>{placeholder}</option>}
      {data.map((item) => (
        <option key={item.value} selected={item.value === value} value={item.value}>{item.label}</option>
      ))}
    </select>
    {typeof helperText === 'string' ? <small className="text-muted">{helperText}</small> : helperText}
    {error && <small className="text-danger mt-1 d-block">{error}</small>}
  </div>
);

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  classNameContainer: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
};

Select.defaultProps = {
  id: '',
  name: '',
  label: '',
  placeholder: '',
  classNameContainer: '',
  className: '',
  helperText: null,
  value: '',
  error: '',
  onChange: () => {},
  onBlur: () => {},
  data: [],
};

export default Select;
