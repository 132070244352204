import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import { Alert } from 'react-bootstrap';
import { useMutation } from 'react-query';

import fileEndpoint from '../../../config/service/endpoint/upload';
import commissionEndpoint from '../../../config/service/endpoint/commission';
import { Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';
import { toRupiah } from '../../../helpers';

const PaymentModal = ({
  clientId, unpaid, bankName, bankAccountName, bankAccountNumber, show, onClose, onRefetch,
}) => {
  const [file, setFile] = useState(null);

  const { mutate: mutatePay, isLoading: isLoadingPay } = useMutation(commissionEndpoint.payCommission, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Komisi berhasil dibayarkann dan bukti transfer berhasil dikirim ke user.');
      onClose();
      onRefetch();
    },
  });

  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } = useMutation(fileEndpoint.uploadFile, {
    onError: (error) => toast.error(error.message),
  });

  const handleUpload = async () => {
    if (!file) {
      toast.error('Silahkan pilih file terlebih dahulu.');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);
    const response = await mutateUpload(formData);
    mutatePay({
      clientId,
      payload: {
        file_name: response?.data?.file_name,
      },
    });
  };

  return (
    <Modal show={show} onClose={onClose} title="Bayar Komisi">
      <table className="table table-borderless table-sm mb-1">
        <thead>
          <tr>
            <td style={{ width: 180 }}>Komisi Belum Dibayar</td>
            <td>{`: ${toRupiah(unpaid)}`}</td>
          </tr>
          <tr>
            <td>No Rekening</td>
            <td>{`: ${bankName && bankAccountName && bankAccountNumber ? `${bankName} ${bankAccountNumber} as ${bankAccountName}` : '-'}`}</td>
          </tr>
          <tr>
            <td>Bukti Transfer</td>
            <td style={{ display: 'flex' }}>
              :&nbsp;&nbsp;
              <InputField
                type="file"
                onChange={(event) => setFile(event.currentTarget.files[0])}
              />
              {file && (
                <a
                  href={URL.createObjectURL(file)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {file.name}
                </a>
              )}
            </td>
          </tr>
        </thead>
      </table>
      <Alert variant="warning">
        Pastikan jumlah yang Anda transfer sesuai dengan komisi yang belum dibayar.
      </Alert>
      <Button isLoading={isLoadingUpload || isLoadingPay} onClick={handleUpload} className="px-3">BAYAR</Button>
    </Modal>
  );
};

PaymentModal.propTypes = {
  clientId: PropTypes.string.isRequired,
  unpaid: PropTypes.number,
  bankName: PropTypes.string,
  bankAccountName: PropTypes.string,
  bankAccountNumber: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onRefetch: PropTypes.func,
};

PaymentModal.defaultProps = {
  unpaid: 0,
  bankName: '',
  bankAccountName: '',
  bankAccountNumber: '',
  show: false,
  onClose: () => { },
  onRefetch: () => { },
};

export default PaymentModal;
