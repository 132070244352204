import configApi from '../configApi';

const getProfile = () => configApi({ endpoint: 'admin/auth/profile' });
const login = (payload) => configApi({ endpoint: 'admin/auth/login', method: 'post', payload });
const updateProfile = (payload) => configApi({ endpoint: 'admin/auth/profile', method: 'put', payload });

const endpoint = {
  getProfile,
  login,
  updateProfile,
};

export default endpoint;
