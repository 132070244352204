import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'admin/commission', params });
const getDetail = (clientId) => configApi({ endpoint: `admin/commission/${clientId}` });
const payCommission = ({ clientId, payload }) => configApi({ endpoint: `admin/commission/${clientId}/pay`, method: 'put', payload });

const endpoint = {
  getAll,
  getDetail,
  payCommission,
};

export default endpoint;
