import {
  closingRateImage, revenueImage, totalLeadImage, totalOrderImage,
} from '../../assets';

const salesOverviewCard = [
  {
    image: totalLeadImage,
    title: 'Total Lead',
    accessor: 'total_lead',
  },
  {
    image: totalOrderImage,
    title: 'Total Order',
    accessor: 'total_order',
  },
  {
    image: closingRateImage,
    title: 'Closing Rate',
    accessor: 'closing_rate',
  },
  {
    image: revenueImage,
    title: 'Revenue',
    accessor: 'revenue',
  },
];

export {
  salesOverviewCard,
};
