import React from 'react';
import Skeleton from 'react-loading-skeleton';

const EditLoadingSkeleton = () => (
  <div className="row">
    <div className="col-md-3">
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
    </div>
    <div className="col-md-9">
      <Skeleton height={100} className="mb-3" />
      <Skeleton height={100} className="mb-3" />
      <Skeleton width={120} height={40} />
      <div className="d-flex mt-4">
        <Skeleton width={120} height={40} />
        <Skeleton width={120} height={40} />
      </div>
    </div>
  </div>
);

export default EditLoadingSkeleton;
