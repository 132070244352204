import React from 'react';
import PropTypes from 'prop-types';
import { Modal as BootstrapModal } from 'react-bootstrap';

const Modal = ({
  show,
  title,
  onClose,
  footerContent,
  size,
  children,
}) => (
  <BootstrapModal size={size} centered show={show} onHide={onClose}>
    <BootstrapModal.Header className="align-items-center">
      <BootstrapModal.Title>{title}</BootstrapModal.Title>
      <i
        className="uil-times"
        style={{ fontSize: 22, cursor: 'pointer' }}
        role="button"
        tabIndex={0}
        onClick={onClose}
        onKeyDown={onClose}
        aria-label="close button"
      />
    </BootstrapModal.Header>
    <BootstrapModal.Body>{children}</BootstrapModal.Body>
    {footerContent && (
      <BootstrapModal.Footer>
        {footerContent}
      </BootstrapModal.Footer>
    )}
  </BootstrapModal>
);

Modal.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func,
  footerContent: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  children: PropTypes.node.isRequired,
};

Modal.defaultProps = {
  show: false,
  title: '',
  size: 'md',
  onClose: () => {},
  footerContent: null,
};

export default Modal;
