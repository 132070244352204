import React from 'react';
import PropTypes from 'prop-types';

const Checkbox = ({
  id, label, className, checked, onChange,
}) => (
  <div className={`custom-control custom-checkbox ${className}`}>
    <input type="checkbox" onChange={onChange} className="custom-control-input" id={id} checked={checked} />
    <label className="custom-control-label" htmlFor={id}>{label}</label>
  </div>
);

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  label: '',
  className: '',
  checked: false,
  onChange: () => {},
};

export default Checkbox;
