import React from 'react';
import Skeleton from 'react-loading-skeleton';

const LoadingSkeleton = () => (
  <>
    <div className="row mb-2">
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <Skeleton width={100} height={22} className="mb-2" />
          <Skeleton height={40} />
        </div>
      </div>
      <div className="col-md-6">
        <Skeleton width={200} height={70} />
        <Skeleton width={50} height={20} className="mt-3" />
        <Skeleton width={200} height={30} className="mt-2 mb-3" />
        <Skeleton width={200} height={150} />
        <Skeleton width={50} height={20} className="mt-3" />
        <Skeleton width={200} height={30} className="mt-2" />
      </div>
    </div>
    <Skeleton width={150} height={40} className="mt-3" />
  </>
);

export default LoadingSkeleton;
