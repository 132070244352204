import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';

import { STATUS_ORDER } from '../../../helpers';

const StatusBadge = ({ status }) => {
  let variant = '';
  let label = '';

  switch (status) {
    case STATUS_ORDER.NEW:
      variant = 'info';
      label = 'Order Baru';
      break;
    case STATUS_ORDER.UNPAID:
      variant = 'warning';
      label = 'Belum Dibayar';
      break;
    case STATUS_ORDER.PAID:
      variant = 'primary';
      label = 'Sudah Dibayar';
      break;
    case STATUS_ORDER.EXPIRED:
      variant = 'secondary';
      label = 'Kadaluarsa';
      break;
    case STATUS_ORDER.COMPLETED:
      variant = 'success';
      label = 'Selesai';
      break;
    case STATUS_ORDER.CANCELED:
      variant = 'danger';
      label = 'Batal';
      break;
    default:
      variant = '';
      label = '';
  }

  return <Badge bg={variant} className="text-white">{label}</Badge>;
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StatusBadge;
