import React from 'react';
import PropTypes from 'prop-types';

import Modal from '../Modal';
import { Button } from '../../core';

const AlertDialog = ({
  show,
  title,
  description,
  labelCancel,
  labelConfirm,
  isDanger,
  isLoadingConfirm,
  onConfirm,
  onClose,
}) => (
  <Modal
    show={show}
    onClose={onClose}
    title={title}
    footerContent={(
      <>
        {labelCancel && (
          <Button variant="light" onClick={onClose}>
            {labelCancel}
          </Button>
        )}
        {labelConfirm && (
          <Button variant={isDanger ? 'danger' : 'primary'} onClick={onConfirm} isLoading={isLoadingConfirm}>
            {labelConfirm}
          </Button>
        )}
      </>
    )}
  >
    {description}
  </Modal>
);

AlertDialog.propTypes = {
  show: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  labelCancel: PropTypes.string,
  labelConfirm: PropTypes.string,
  isDanger: PropTypes.bool,
  isLoadingConfirm: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
};

AlertDialog.defaultProps = {
  show: false,
  title: '',
  description: '',
  labelCancel: '',
  labelConfirm: '',
  isDanger: false,
  isLoadingConfirm: false,
  onConfirm: () => {},
  onClose: () => {},
};

export default AlertDialog;
