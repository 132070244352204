import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import LoadingOverlay from 'react-loading-overlay-ts';
import { useSearchParams } from 'react-router-dom';
import { Alert } from 'react-bootstrap';

const Table = ({
  totalData, perPage, isLoading, changePageWithParams, headRender, bodyRender, onFetchData,
}) => {
  const [currentPage, setCurrentPage] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (onFetchData) onFetchData(currentPage);
    if (currentPage && changePageWithParams) setSearchParams({ page: currentPage });
  }, [currentPage]);

  return (
    <LoadingOverlay
      spinner
      active={isLoading}
      fadeSpeed={300}
      text="Loading..."
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(0, 0, 0, 0.3)',
        }),
      }}
    >
      {totalData > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-light">
              {headRender}
            </thead>
            <tbody>
              {bodyRender}
            </tbody>
          </table>
          {totalData > 10 && (
            <div className="d-flex justify-content-center mt-4">
              <ReactPaginate
                disableInitialCallback
                nextLabel="Next"
                previousLabel="Prev"
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={Math.ceil(totalData / perPage)}
                onPageChange={(event) => setCurrentPage(event.selected + 1)}
                initialPage={(Number(searchParams.get('page')) || 1) - 1}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
        </div>
      ) : (
        <Alert variant="warning" className="mb-0">Tidak ada data yang dapat ditampilan.</Alert>
      )}
    </LoadingOverlay>
  );
};

Table.propTypes = {
  totalData: PropTypes.number,
  perPage: PropTypes.number,
  isLoading: PropTypes.bool,
  changePageWithParams: PropTypes.bool,
  headRender: PropTypes.node.isRequired,
  bodyRender: PropTypes.node,
  onFetchData: PropTypes.func,
};

Table.defaultProps = {
  totalData: 0,
  perPage: 10,
  isLoading: false,
  changePageWithParams: true,
  bodyRender: null,
  onFetchData: undefined,
};

export default Table;
