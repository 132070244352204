import React from 'react';
import { Outlet } from 'react-router-dom';

import { getSessionStorage } from '../../helpers';

const PublicRoute = () => {
  const tokenStorage = getSessionStorage('token');
  if (tokenStorage) {
    window.location.href = '/dashboard';
    return null;
  }
  return <Outlet />;
};

export default PublicRoute;
