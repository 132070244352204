import React from 'react';
import PropTypes from 'prop-types';

const Toggle = ({
  id, name, label, className, checked, onChange,
}) => (
  <div
    className={`custom-control custom-switch ${className}`}
    role="button"
    tabIndex={0}
    onClick={onChange}
    onKeyDown={onChange}
  >
    <input type="checkbox" name={name} className="custom-control-input" id={id} checked={checked} />
    <label className="custom-control-label" htmlFor={id}>{label}</label>
  </div>
);

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Toggle.defaultProps = {
  name: '',
  label: '',
  className: '',
  checked: false,
  onChange: () => {},
};

export default Toggle;
