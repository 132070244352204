import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import productEndpoint from '../../config/service/endpoint/product';
import { AlertDialog, Table } from '../../components/elements';
import { toRupiah } from '../../helpers';
import { Button } from '../../components/core';

const ProductPage = () => {
  const [productDelete, setProductDelete] = useState(0);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: products, refetch } = useQuery('template', () => productEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(productEndpoint.deleteProduct, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Product berhasil dihapus.');
      setProductDelete(0);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
  }, [searchParams]);

  return (
    <>
      <h4 className="mb-4">Produk</h4>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <Link to="/produk/tambah">
            <Button>TAMBAH PRODUK</Button>
          </Link>
        </div>
        <div className="card-body">
          <Table
            totalData={products?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th>Foto</th>
                <th>Judul Produk</th>
                <th>Harga</th>
                <th>Total Order</th>
                <th>Total Omset</th>
                <th>Aksi</th>
              </tr>
            )}
            bodyRender={products?.data?.products?.map((product) => (
              <tr key={product}>
                <td>
                  <Link target="_blank" to={`${process.env.REACT_APP_SERVICE_URL_API}uploads/${product.image}`}>
                    <img
                      src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/${product.image}`}
                      alt={product.title}
                      width={80}
                      height={80}
                      style={{ objectFit: 'cover' }}
                    />
                  </Link>
                </td>
                <td>{product.product_name}</td>
                <td>{toRupiah(product.price)}</td>
                <td>{product.total_order}</td>
                <td>{toRupiah(product.total_omset)}</td>
                <td width={130} aria-label="action">
                  <Link target="_blank" to={`${process.env.REACT_APP_CHECKOUT_URL}${product.slug}`}>
                    <Button variant="success" size="sm" className="mr-1">
                      <i className="uil-eye" />
                    </Button>
                  </Link>
                  <Link to={`/produk/${product.id}`}>
                    <Button variant="info" size="sm" className="mr-1">
                      <i className="uil-pen" />
                    </Button>
                  </Link>
                  <Button variant="danger" size="sm" onClick={() => setProductDelete(product.id)}>
                    <i className="uil-trash-alt" />
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AlertDialog
        isDanger
        show={!!productDelete}
        title="Hapus Produk?"
        description="Apakah kamu yakin ingin menghapus produk ini?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setProductDelete(0)}
        onConfirm={() => deleteMutate(productDelete)}
      />
    </>
  );
};

export default ProductPage;
