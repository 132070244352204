import React from 'react';
import { Link } from 'react-router-dom';

import { profileImage } from '../../../../assets';
import { TOP_BAR_MENU, getSessionStorage } from '../../../../helpers';

const userStorage = getSessionStorage('user');
const userData = userStorage ? JSON.parse(userStorage) : { name: '-' };

const PageTopbar = () => (
  <header id="page-topbar">
    <div className="navbar-header">
      <div className="d-flex">
        <div className="navbar-brand-box">
          <a href="/administrator" className="logo">
            <span className="logo-sm">
              <img
                src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-icon.png`}
                alt="logo"
                height={30}
              />
            </span>
            <span className="logo-lg">
              <img
                src={`${process.env.REACT_APP_SERVICE_URL_API}uploads/special-logo.png`}
                alt="logo"
                height={30}
              />
            </span>
          </a>
        </div>
        <button
          aria-label="menu"
          type="button"
          className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
        >
          <i className="fa fa-fw fa-bars" />
        </button>
      </div>
      <div className="d-flex">
        <div className="dropdown d-inline-block">
          <button
            type="button"
            className="btn header-item waves-effect"
            id="page-header-user-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              className="rounded-circle header-profile-user"
              src={profileImage}
              alt="Header Avatar"
            />
            <span className="d-none d-xl-inline-block ml-1 font-weight-medium font-size-15">
              {userData?.name}
            </span>
            <i className="uil-angle-down d-none d-xl-inline-block font-size-15" />
          </button>
          <div className="dropdown-menu dropdown-menu-right">
            {TOP_BAR_MENU.map((menu) => (
              <Link key={menu.name} className="dropdown-item d-block" to={menu.link}>
                <i className={`uil uil-${menu.icon} font-size-18 align-middle mr-1 text-muted`} />
                <span className="align-middle">{menu.label}</span>
              </Link>
            ))}
            <a
              className="dropdown-item"
              href="#"
              onClick={() => {
                window.localStorage.removeItem('token');
                window.location.href = '/';
              }}
            >
              <i className="uil uil-sign-out-alt font-size-18 align-middle mr-1 text-muted" />
              <span className="align-middle">Keluar</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default PageTopbar;
