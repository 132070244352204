import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'admin/templates', params });
const getById = (id) => configApi({ endpoint: `admin/templates/${id}` });
const createTemplate = (payload) => configApi({ endpoint: 'admin/templates', method: 'post', payload });
const updateTemplate = ({ id, payload }) => configApi({ endpoint: `admin/templates/${id}`, method: 'put', payload });
const deleteTemplate = (payload) => configApi({ endpoint: 'admin/templates', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};

export default endpoint;
