import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { useMutation, useQuery } from 'react-query';

import Menu from '../components/Menu';
import LoadingSkeleton from './LoadingSkeleton';
import settingEndpoint from '../../../config/service/endpoint/setting';
import {
  Button, InputField, Radio, Select, Toggle,
} from '../../../components/core';

const LIST_BANK_NAME = [
  { value: 'BCA', label: 'BCA' },
  { value: 'BNI', label: 'BNI' },
  { value: 'BRI', label: 'BRI' },
  { value: 'Mandiri', label: 'Mandiri' },
];

const SettingPaymentPage = () => {
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(settingEndpoint.updateSetting, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Berhasil menyimpan pengaturan.'),
  });

  const formik = useFormik({
    initialValues: {
      is_payment_midtrans: false,
      payment_uniq_code: false,
      bank_transfer_bank_name: '',
      bank_transfer_account_number: '',
      bank_transfer_account_name: '',
      midtrans_environment: '',
      midtrans_client_key: '',
      midtrans_server_key: '',
      minutes_expired: 0,
    },
    validationSchema: yup.object({
      bank_transfer_account_number: yup.string().trim().max(50, 'Maksimal 50 karakter.'),
      bank_transfer_account_name: yup.string().trim().max(50, 'Maksimal 50 karakter.'),
      midtrans_environment: yup.string().trim().required('Midtrans environment harus dipilih.'),
      midtrans_client_key: yup.string().trim().required('Midtrans client key harus diisi.').max(100, 'Maksimal 100 karakter.'),
      midtrans_server_key: yup.string().trim().required('Midtrans server key harus diisi.').max(100, 'Maksimal 100 karakter.'),
      minutes_expired: yup.number().typeError('Menit harus berupa angka.').required('Menit kadaluarsa harus diisi.').min(1, 'Kadaluarsa minimal 1 menit.'),
    }),
    onSubmit: (values) => {
      mutateUpdate({
        is_payment_midtrans: values.is_payment_midtrans,
        payment_uniq_code: values.payment_uniq_code,
        bank_transfer_bank_name: values.bank_transfer_bank_name,
        bank_transfer_account_number: values.bank_transfer_account_number,
        bank_transfer_account_name: values.bank_transfer_account_name,
        midtrans_environment: values.midtrans_environment,
        midtrans_client_key: values.midtrans_client_key,
        midtrans_server_key: values.midtrans_server_key,
        minutes_expired: values.minutes_expired,
      });
    },
  });

  const { isFetching: isLoadingFetch } = useQuery('setting', settingEndpoint.setting, {
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const { data: newData } = data;
      formik.setFieldValue('is_payment_midtrans', newData.is_payment_midtrans);
      formik.setFieldValue('payment_uniq_code', newData.payment_uniq_code);
      formik.setFieldValue('bank_transfer_bank_name', newData.bank_transfer_bank_name);
      formik.setFieldValue('bank_transfer_account_number', newData.bank_transfer_account_number);
      formik.setFieldValue('bank_transfer_account_name', newData.bank_transfer_account_name);
      formik.setFieldValue('midtrans_environment', newData.midtrans_environment);
      formik.setFieldValue('midtrans_client_key', newData.midtrans_client_key);
      formik.setFieldValue('midtrans_server_key', newData.midtrans_server_key);
      formik.setFieldValue('minutes_expired', newData.minutes_expired);
    },
  });

  return (
    <>
      <h4 className="mb-4">Pengaturan</h4>
      <div className="card">
        <div className="card-body">
          <Menu tab="payment" />
          {isLoadingFetch ? (
            <LoadingSkeleton />
          ) : (
            <div className="row">
              <div className="col-md-6">
                <FormikProvider value={formik}>
                  <form onSubmit={formik.handleSubmit} className="mt-4">
                    <Toggle
                      name="is_payment_midtrans"
                      id="paymentMidtrans"
                      className="mb-2"
                      label="Aktifkan Pembayaran Midtrans"
                      checked={formik.values.is_payment_midtrans}
                      onChange={formik.handleChange}
                    />
                    <Toggle
                      name="payment_uniq_code"
                      id="uniqCode"
                      label="Aktifkan Kode Unik"
                      checked={formik.values.payment_uniq_code}
                      onChange={formik.handleChange}
                    />
                    <hr />
                    <Select
                      id="bank_transfer_bank_name"
                      name="bank_transfer_bank_name"
                      label="Nama Bank"
                      placeholder="Pilih Bank"
                      data={LIST_BANK_NAME}
                      value={formik.values.bank_transfer_bank_name}
                      error={formik.touched.bank_transfer_bank_name && formik.errors.bank_transfer_bank_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      id="bank_transfer_account_number"
                      name="bank_transfer_account_number"
                      label="Nomor Rekening"
                      value={formik.values.bank_transfer_account_number}
                      error={formik.touched.bank_transfer_account_number && formik.errors.bank_transfer_account_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      id="bank_transfer_account_name"
                      name="bank_transfer_account_name"
                      label="Nama Pemilik Rekening"
                      value={formik.values.bank_transfer_account_name}
                      error={formik.touched.bank_transfer_account_name && formik.errors.bank_transfer_account_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <hr />
                    <div className="form-group">
                      <label>Midtrans Environment</label>
                      <div className="d-flex" style={{ gap: 20 }}>
                        <Radio
                          id="midtrans_development"
                          name="midtrans_environment"
                          label="Sandbox (Test)"
                          value="development"
                          checked={formik.values.midtrans_environment === 'development'}
                          onChange={formik.handleChange}
                        />
                        <Radio
                          id="midtrans_production"
                          name="midtrans_environment"
                          label="Production"
                          value="production"
                          checked={formik.values.midtrans_environment === 'production'}
                          onChange={formik.handleChange}
                        />
                      </div>
                      {formik.touched.midtrans_environment && formik.errors.midtrans_environment && (
                        <small className="text-danger">{formik.errors.midtrans_environment}</small>
                      )}
                    </div>
                    <InputField
                      id="midtrans_client_key"
                      name="midtrans_client_key"
                      label="Midtrans Client Key"
                      value={formik.values.midtrans_client_key}
                      error={formik.touched.midtrans_client_key && formik.errors.midtrans_client_key}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <InputField
                      id="midtrans_server_key"
                      name="midtrans_server_key"
                      label="Midtrans Server Key"
                      value={formik.values.midtrans_server_key}
                      error={formik.touched.midtrans_server_key && formik.errors.midtrans_server_key}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="form-group">
                      <label htmlFor="expiredTime">Kadaluarsa Dalam</label>
                      <div className="input-group" style={{ maxWidth: 250 }}>
                        <input
                          name="minutes_expired"
                          type="text"
                          id="expiredTime"
                          className="form-control"
                          value={formik.values.minutes_expired}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        <div className="input-group-append">
                          <span className="px-4 input-group-text">menit</span>
                        </div>
                      </div>
                      {formik.touched.minutes_expired && formik.errors.minutes_expired && (
                        <small className="text-danger">{formik.errors.minutes_expired}</small>
                      )}
                    </div>
                    <Button
                      isLoading={isLoadingUpdate}
                      type="submit"
                      variant="success"
                      className="px-4"
                    >
                      SIMPAN
                    </Button>
                  </form>
                </FormikProvider>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SettingPaymentPage;
