import React from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import Menu from '../components/Menu';
import LoadingSkeleton from './LoadingSkeleton';
import settingEndpoint from '../../../config/service/endpoint/setting';
import fileEndpoint from '../../../config/service/endpoint/upload';
import { Button, InputField } from '../../../components/core';

const SettingAccountPage = () => {
  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } = useMutation(fileEndpoint.uploadFile, {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } = useMutation(settingEndpoint.updateSetting, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => toast.success('Berhasil mengubah pengaturan.'),
  });

  const formik = useFormik({
    initialValues: {
      business_name: '',
      business_whatsapp: '',
      business_icon: '',
      business_icon_value: '',
      business_logo: '',
      business_logo_value: '',
    },
    validationSchema: yup.object({
      business_name: yup.string().trim().required('Nama usaha harus diisi.').max(100, 'Maksimal 100 karakter'),
      business_whatsapp: yup.string().trim().required('No WhatsApp harus diisi.').max(20, 'Maksimal 20 karakter'),
    }),
    validate: (values) => {
      if (values.business_icon && values.business_icon.size > 500000) {
        return { business_icon: 'Ukuran icon maksimal 2 MB.' };
      }
      if (values.business_icon && !['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(values.business_icon.type)) {
        return { business_icon: 'Format icon harus JPG, PNG, atau SVG.' };
      }
      if (values.business_logo && values.business_logo.size > 500000) {
        return { business_logo: 'Ukuran logo maksimal 2 MB.' };
      }
      if (values.business_logo && !['image/jpeg', 'image/jpg', 'image/png', 'image/svg+xml'].includes(values.business_logo.type)) {
        return { business_logo: 'Format logo harus JPG, PNG, atau SVG.' };
      }
      return null;
    },
    onSubmit: async (values) => {
      let businessIcon = '';
      let businessLogo = '';
      {
        const formData = new FormData();
        formData.append('file', values.business_icon);
        formData.append('is_icon', true);
        if (values.business_icon) {
          const response = await mutateUpload(formData);
          if (response.success) businessIcon = response.data.file_name;
        }
      }
      {
        const formData = new FormData();
        formData.append('file', values.business_logo);
        formData.append('is_logo', true);
        if (values.business_logo) {
          const response = await mutateUpload(formData);
          if (response.success) businessLogo = response.data.file_name;
        }
      }
      mutateUpdate({
        business_name: values.business_name,
        business_whatsapp: values.business_whatsapp,
        business_icon: businessIcon,
        business_logo: businessLogo,
      });
    },
  });

  const { isFetching: isLoadingFetch } = useQuery('setting', settingEndpoint.setting, {
    onError: (error) => toast.error(error.message),
    onSuccess: (data) => {
      const { data: newData } = data;
      formik.setFieldValue('business_name', newData.business_name);
      formik.setFieldValue('business_whatsapp', newData.business_whatsapp);
      formik.setFieldValue('business_icon_value', newData.business_icon);
      formik.setFieldValue('business_logo_value', newData.business_logo);
    },
  });

  return (
    <>
      <h4 className="mb-4">Pengaturan</h4>
      <div className="card">
        <div className="card-body">
          <Menu tab="account" />
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              {isLoadingFetch ? (
                <LoadingSkeleton />
              ) : (
                <>
                  <div className="row mb-2">
                    <div className="col-md-6">
                      <InputField
                        id="business_name"
                        name="business_name"
                        label="Nama Usaha"
                        value={formik.values.business_name}
                        error={formik.touched.business_name && formik.errors.business_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputField
                        id="business_whatsapp"
                        name="business_whatsapp"
                        label="No WhatsApp"
                        value={formik.values.business_whatsapp}
                        error={formik.touched.business_whatsapp && formik.errors.business_whatsapp}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="col-md-6">
                      {(formik.values.business_icon || formik.values.business_icon_value) && (
                        <img
                          style={{ width: 200 }}
                          src={formik.values.business_icon ? URL.createObjectURL(formik.values.business_icon) : `${process.env.REACT_APP_SERVICE_URL_API}uploads/${formik.values.business_icon_value}`}
                          alt="business icon"
                        />
                      )}
                      <InputField
                        id="business_icon"
                        name="business_icon"
                        type="file"
                        label="Icon"
                        value={undefined}
                        error={formik.touched.business_icon && formik.errors.business_icon}
                        onChange={(event) => formik.setFieldValue('business_icon', event.currentTarget.files[0])}
                        onBlur={formik.handleBlur}
                      />
                      {(formik.values.business_logo || formik.values.business_logo_value) && (
                        <img
                          style={{ width: 200 }}
                          src={formik.values.business_logo ? URL.createObjectURL(formik.values.business_logo) : `${process.env.REACT_APP_SERVICE_URL_API}uploads/${formik.values.business_logo_value}`}
                          alt="business logo"
                        />
                      )}
                      <InputField
                        id="business_logo"
                        name="business_logo"
                        type="file"
                        label="Logo"
                        value={undefined}
                        error={formik.touched.business_logo && formik.errors.business_logo}
                        onChange={(event) => formik.setFieldValue('business_logo', event.currentTarget.files[0])}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <Button
                    type="submit"
                    isLoading={isLoadingUpload || isLoadingUpdate}
                  >
                    Simpan Pengaturan
                  </Button>
                </>
              )}
            </form>
          </FormikProvider>
        </div>
      </div>
    </>
  );
};

export default SettingAccountPage;
