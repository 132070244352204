import React, { useState } from 'react';

import OrderSummary from './components/OrderSummary';
import Chart from './components/Chart';
import { Dropdown } from '../../components/elements';

const filterOptions = [
  { value: 'all', label: 'Semua' },
  { value: 'today', label: 'Hari Ini' },
  { value: 'month', label: 'Bulan Ini' },
  { value: 'year', label: 'Tahun Ini' },
];

const DashboardPage = () => {
  const [filter, setFilter] = useState('all');

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="mb-0">Dashboard</h4>
        <Dropdown
          label={`Filter${filter === 'all' ? '' : `: ${filterOptions.find((option) => option.value === filter).label}`}`}
          data={filterOptions}
          onChoose={setFilter}
        />
      </div>
      <OrderSummary filter={filter} />
      <Chart />
    </>
  );
};

export default DashboardPage;
