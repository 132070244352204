import React from 'react';
import toast from 'react-hot-toast';
import { Bar } from 'react-chartjs-2';
import { useQuery } from 'react-query';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from 'chart.js';

import dashboardEndpoint from '../../../config/service/endpoint/dashboard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  aspectRatio: 3,
  borderRadius: 4,
  barThickness: 30,
  plugins: {
    legend: {
      position: 'bottom',
    },
  },
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
};

const Chart = () => {
  const { data, isFetching } = useQuery('dashboard-chart', dashboardEndpoint.getChart, {
    onError: (error) => toast.error(error.message),
  });

  if (isFetching) return null;

  const chartData = {
    labels: data?.data?.map((_item) => _item.month),
    datasets: [
      {
        label: 'Order',
        data: data?.data?.map((_item) => _item.order),
        backgroundColor: '#FFC000',
      },
      {
        label: 'Lead',
        data: data?.data?.map((_item) => _item.lead),
        backgroundColor: '#4E73DF',
      },
    ],
  };

  return (
    <div className="card">
      <div className="card-body">
        <Bar options={options} data={chartData} />
      </div>
    </div>
  );
};

export default Chart;
