import configApi from '../configApi';

const getAll = (params) => configApi({ endpoint: 'admin/users', params });
const getById = (id) => configApi({ endpoint: `admin/users/${id}` });
const updateUser = (payload) => configApi({ endpoint: `admin/users/${payload.id}`, method: 'put', payload });
const deleteUser = (id) => configApi({ endpoint: `admin/users/${id}`, method: 'delete' });
const deleteMultipleUser = (payload) => configApi({ endpoint: 'admin/users', method: 'delete', payload });

const endpoint = {
  getAll,
  getById,
  updateUser,
  deleteUser,
  deleteMultipleUser,
};

export default endpoint;
