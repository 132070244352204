import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';

import AddFormModal from './components/AddFormModal';
import templateEndpoint from '../../config/service/endpoint/template';
import { AlertDialog, Table } from '../../components/elements';
import { Button, Checkbox } from '../../components/core';

const TemplatePage = () => {
  const [openedAddModal, setOpenedAddModal] = useState(false);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [isCheckAll, setCheckAll] = useState(false);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: templateData, refetch } = useQuery('template', () => templateEndpoint.getAll({ page: currentPage }), {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(templateEndpoint.deleteTemplate, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menghapus template yangd dipilih.');
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  useEffect(() => {
    setCheckAll((selectedData.length === templateData?.data?.templates?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newTemplate = templateData?.data?.templates.map((template) => template.id);
      setSelectedData(newTemplate);
    }
  };

  const handleClickDeleteButton = () => {
    if (selectedData.length < 1) {
      toast.error('Pilih minimal 1 data yang akan dihapus.');
      return;
    }
    setOpenedConfirmDelete(true);
  };

  return (
    <>
      <h4 className="mb-4">Template</h4>
      <div className="card">
        <div className="card-header d-flex align-items-center">
          <Button className="mr-2 px-4" onClick={() => setOpenedAddModal(true)}>Tambah</Button>
          <Button variant="danger" onClick={handleClickDeleteButton}>
            <i className="uil-trash-alt" />
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={templateData?.data?.meta?.total}
            isLoading={isFetching}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Kategori</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={templateData?.data?.templates?.map((template) => (
              <tr key={template.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${template.id}`}
                    checked={selectedData.includes(template.id)}
                    onChange={() => handleCheck(template.id)}
                  />
                </td>
                <td>{template.category_name}</td>
                <td width={100} aria-label="action">
                  <Link to={`/template/${template.id}`}>
                    <Button size="sm" className="px-3">
                      Edit
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <AddFormModal
        show={openedAddModal}
        onClose={() => setOpenedAddModal(false)}
        onRefetch={refetch}
      />
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus Template?"
        description="Apakah kamu yakin ingin menghapus data template yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => {
          deleteMutate({
            ids: selectedData,
          });
        }}
      />
    </>
  );
};

export default TemplatePage;
