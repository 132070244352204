import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { useSearchParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';

import EditModal from './components/EditModal';
import userEndpoint from '../../config/service/endpoint/user';
import { Button, Checkbox, InputField } from '../../components/core';
import { AlertDialog, Table } from '../../components/elements';

const UserPage = () => {
  const [editUserId, setEditUserId] = useState(null);
  const [openedConfirmDelete, setOpenedConfirmDelete] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: users, refetch } = useQuery('users', () => userEndpoint.getAll({ page: currentPage, search: searchValue }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  const { mutate: deleteMutate, isLoading: isLoadingDelete } = useMutation(userEndpoint.deleteMultipleUser, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menghapus user yang dipilih.');
      setOpenedConfirmDelete(false);
      refetch();
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  useEffect(() => {
    setCheckAll((selectedData.length === users?.data?.users?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newUser = users?.data?.users.map((user) => user.id);
      setSelectedData(newUser);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  return (
    <>
      <h4 className="mb-4">User</h4>
      <div className="card">
        <div className="card-header d-flex align-items-center justify-content-between">
          <form>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
          </form>
          <Button
            className="px-4"
            variant="danger"
            onClick={() => {
              if (selectedData.length > 0) setOpenedConfirmDelete(true);
              else toast.error('Pilih minimal 1 data yang akan di hapus.');
            }}
          >
            HAPUS
          </Button>
        </div>
        <div className="card-body">
          <Table
            totalData={users?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Nama</th>
                <th>No WhatsApp</th>
                <th>Email</th>
                <th>Status</th>
                <th>Kadaluarsa</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={users?.data?.users.map((user) => (
              <tr key={user.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${user.id}`}
                    checked={selectedData.includes(user.id)}
                    onChange={() => handleCheck(user.id)}
                  />
                </td>
                <td>
                  {user.name}
                  {user.affiliate_only && (
                    <>
                      <br />
                      <Badge bg="secondary" className="text-white">affiliate only</Badge>
                    </>
                  )}
                </td>
                <td>{user.whatsapp}</td>
                <td>{user.email}</td>
                <td>{user.is_active ? 'Aktif' : 'Tidak Aktif'}</td>
                <td>{user.affiliate_only ? '-' : moment(user.expired_date).format('DD MMM YYYY')}</td>
                <td width={100} aria-label="action">
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="px-3"
                    onClick={() => setEditUserId(user.id)}
                  >
                    Edit
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <EditModal
        userId={editUserId}
        show={!!editUserId}
        onClose={() => setEditUserId(null)}
        onOpen={setEditUserId}
        onFetch={refetch}
      />
      <AlertDialog
        isDanger
        show={openedConfirmDelete}
        title="Hapus user?"
        description="Apakah kamu yakin ingin menghapus data user yang dipilih?"
        labelCancel="Batal"
        labelConfirm="Ya, Yakin"
        isLoadingConfirm={isLoadingDelete}
        onClose={() => setOpenedConfirmDelete(false)}
        onConfirm={() => {
          deleteMutate({
            ids: selectedData,
          });
        }}
      />
    </>
  );
};

export default UserPage;
