import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const menusData = [
  {
    name: 'account',
    label: 'Akun',
    url: '/pengaturan/akun',
    icon: 'far fa-user-circle',
  },
  {
    name: 'payment',
    label: 'Pembayaran',
    url: '/pengaturan/pembayaran',
    icon: 'fas fa-dollar-sign',
  },
  {
    name: 'notification',
    label: 'Notifikasi',
    url: '/pengaturan/notifikasi',
    icon: 'far fa-comment-dots',
  },
];

const Menu = ({ tab }) => (
  <>
    <ul className="nav">
      {menusData.map((menu) => (
        <li className="nav-item" key={menu.name}>
          <Link to={menu.url} className={`nav-link ${menu.name === tab ? 'text-success' : 'text-secondary'}`}>
            <i className={`${menu.icon} mr-1`} />
            {' '}
            {menu.label}
          </Link>
        </li>
      ))}
    </ul>
    <hr />
  </>
);

Menu.propTypes = {
  tab: PropTypes.string,
};

Menu.defaultProps = {
  tab: '',
};

export default Menu;
