import React, { useState } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { FormikProvider, useFormik } from 'formik';

import LoadingSkeleton from './LoadingSkeleton';
import userEndpoint from '../../../config/service/endpoint/user';
import { AlertDialog, Modal } from '../../../components/elements';
import { Button, InputField } from '../../../components/core';

const EditModal = ({
  userId, show, onClose, onOpen, onFetch,
}) => {
  const [userIdDelete, setUserIdDelete] = useState(null);

  const { mutate: mutateUpdate, isLoading: updateLoading } = useMutation('user', userEndpoint.updateUser, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah data user');
      onFetch();
      onClose();
    },
  });

  const { mutate: mutateDelete, isLoading: deleteLoading } = useMutation(userEndpoint.deleteUser, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('User berhasil dihapus.');
      onFetch();
      setUserIdDelete(null);
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp: '',
      email: '',
      password: '',
    },
    validationSchema: yup.object({
      name: yup.string().trim().required('Nama harus diisi.').max(50, 'Nama maksimal 50 karakter.'),
      whatsapp: yup.number().typeError('No WhatsApp harus berupa angka.').required('No WhatsApp harus diisi.').test('len', 'No WhatsApp maksimal 15 karakter', (val) => val.toString().length < 15),
      password: yup.string().trim().optional().min(5, 'Password minimal 5 karakter.'),
    }),
    onSubmit: (values) => {
      mutateUpdate({
        id: userId,
        name: values.name,
        whatsapp: values.whatsapp,
        password: values.password,
      });
    },
  });

  const { isFetching } = useQuery('user', () => userEndpoint.getById(userId), {
    onError: (error) => toast.error(error.message),
    enabled: !!userId,
    onSuccess: (data) => {
      const { data: resData } = data;
      formik.setFieldValue('name', resData.name);
      formik.setFieldValue('whatsapp', resData.whatsapp);
      formik.setFieldValue('email', resData.email);
    },
  });

  return (
    <>
      <Modal show={show} onClose={onClose} title="Edit User">
        {isFetching ? <LoadingSkeleton /> : (
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <InputField
                    id="name"
                    name="name"
                    label="Nama"
                    error={formik.touched.name && formik.errors.name}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    id="whatsapp"
                    name="whatsapp"
                    label="No WhatsApp"
                    error={formik.touched.whatsapp && formik.errors.whatsapp}
                    value={formik.values.whatsapp}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    readOnly
                    id="email"
                    name="email"
                    label="Email"
                    error={formik.touched.email && formik.errors.email}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-md-6">
                  <InputField
                    id="password"
                    name="password"
                    label="Password"
                    error={formik.touched.password && formik.errors.password}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="d-flex mt-2">
                <Button
                  type="submit"
                  isLoading={updateLoading}
                  className="px-3 mr-2"
                >
                  SIMPAN
                </Button>
                <Button
                  variant="danger"
                  className="px-3"
                  onClick={() => {
                    setUserIdDelete(userId);
                    onClose();
                  }}
                >
                  HAPUS
                </Button>
              </div>
            </form>
          </FormikProvider>
        )}
      </Modal>
      <AlertDialog
        isDanger
        show={!!userIdDelete}
        title="Hapus User?"
        description="Apakah kamu yakin ingin menghapus user ini?"
        labelCancel="Batal"
        labelConfirm="Ya, Hapus"
        isLoadingConfirm={deleteLoading}
        onConfirm={() => mutateDelete(userIdDelete)}
        onClose={() => {
          setUserIdDelete(null);
          onOpen(userIdDelete);
        }}
      />
    </>
  );
};

EditModal.propTypes = {
  userId: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  onFetch: PropTypes.func,
};

EditModal.defaultProps = {
  userId: '',
  show: false,
  onClose: () => {},
  onOpen: () => {},
  onFetch: () => {},
};

export default EditModal;
