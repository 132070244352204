import React from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import fileEndpoint from '../../../config/service/endpoint/upload';
import productEndpoint from '../../../config/service/endpoint/product';
import { Button, InputField, Select } from '../../../components/core';

const paymentPlanOptions = [
  { value: 7, label: 'Trial 7 Hari' },
  { value: 30, label: 'Bulanan 30 Hari' },
  { value: 365, label: 'Tahunan 365 Hari' },
];

const commissionTypeOptions = [
  { value: '', label: 'Tanpa Komisi' },
  { value: 'percentage', label: 'Persentase' },
  { value: 'amount', label: 'Rupiah' },
];

const statusOptions = [
  { value: 'publish', label: 'Publish' },
  { value: 'draft', label: 'Draft' },
];

const Form = ({ initialData }) => {
  const navigate = useNavigate();

  const { mutateAsync: mutateUpload, isLoading: isLoadingUpload } = useMutation(fileEndpoint.uploadFile, {
    onError: (error) => toast.error(error.message),
  });

  const { mutate: mutateSubmit, isLoading: isLoadingSubmit } = useMutation(initialData.id ? productEndpoint.updateProduct : productEndpoint.insertProduct, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil menyimpan produk.');
      navigate('/produk');
    },
  });

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append('file', values.image);
      let response;
      if (!initialData.id) {
        response = await mutateUpload(formData);
        if (!response.success) throw new Error(response.message);
      }
      mutateSubmit({
        id: initialData ? initialData.id : 0,
        product_name: values.product_name,
        price: values.price ? Number(values.price) : 0,
        payment_plan: values.payment_plan ? Number(values.payment_plan) : 0,
        link_bonus: values.link_bonus,
        commission_amount: values.commission_amount ? Number(values.commission_amount) : 0,
        commission_type: values.commission_type,
        image: response ? response.data.file_name : initialData.image,
        status: values.status,
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      product_name: initialData.product_name,
      price: initialData.price || '',
      payment_plan: initialData.payment_plan,
      link_bonus: initialData.link_bonus,
      commission_amount: initialData.commission_amount || '',
      commission_type: initialData.commission_type,
      image: initialData.image,
      status: initialData.status,
    },
    validationSchema: yup.object({
      product_name: yup.string().trim().required('Nama produk harus diisi.').max(100, 'Nama produk maksimal 100 karakter.'),
      price: yup.number().typeError('Harga produk harus berupa angka.').required('Harga produk harus diisi.').min(0, 'Harga produk minimal 0.'),
      payment_plan: yup.number().required('Pilih rencana pembayaran terlebih dahulu.'),
      link_bonus: yup.string().trim().max(150, 'Link bonus maksimal 150 karakter.'),
      commission_amount: yup.number().typeError('Komisi harus berupa angka.').min(1, 'Komisi minimal 1.'),
      image: yup.string().trim().required('Gambar tidak boleh kosong.'),
      status: yup.string().trim().required('Pilih status terlebih dahulu.'),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <InputField
              id="productName"
              name="product_name"
              label="Nama Produk"
              value={formik.values.product_name}
              error={formik.touched.product_name && formik.errors.product_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="price"
              name="price"
              label="Harga Produk"
              value={formik.values.price}
              error={formik.touched.price && formik.errors.price}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <Select
              id="paymentPlan"
              name="payment_plan"
              label="Rencana Pembayaran"
              placeholder="Pilih Waktu"
              data={paymentPlanOptions}
              error={formik.touched.payment_plan && formik.errors.payment_plan}
              value={formik.values.payment_plan}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="linkBonus"
              name="link_bonus"
              label="Link Bonus"
              placeholder="https://"
              value={formik.values.link_bonus}
              error={formik.touched.link_bonus && formik.errors.link_bonus}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <InputField
              id="commissionAmount"
              name="commission_amount"
              label="Komisi Affiliasi"
              value={formik.values.commission_amount}
              error={formik.touched.commission_amount && formik.errors.commission_amount}
              helperText="Contoh jika tipe komisi adalah rupiah: 100000"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <Select
              id="commissionType"
              name="commission_type"
              label="Tipe Komisi"
              data={commissionTypeOptions}
              value={formik.values.commission_type}
              error={formik.touched.commission_type && formik.errors.commission_type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            {formik.values.image && (
              <a href={formik.values.image} target="_blank" rel="noreferrer">
                <img
                  style={{ width: 300 }}
                  src={initialData.image ? `${process.env.REACT_APP_SERVICE_URL_API}uploads/${formik.values.image}` : URL.createObjectURL(formik.values.image)}
                  alt="product"
                />
              </a>
            )}
            <InputField
              id="image"
              name="image"
              type="file"
              label="Image"
              helperText="Minimal 2MB. Format: PNG, JPEG, atau JPG."
              value={undefined}
              error={formik.touched.image && formik.errors.image}
              onChange={(event) => formik.setFieldValue('image', event.currentTarget.files[0])}
              onBlur={formik.handleBlur}
            />
          </div>
          <div className="col-md-6">
            <Select
              id="productStatus"
              name="status"
              label="Status"
              placeholder="Pilih Status"
              data={statusOptions}
              value={formik.values.status}
              error={formik.touched.status && formik.errors.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <div className="d-flex">
          <Button
            type="submit"
            className="mr-2"
            isLoading={isLoadingSubmit || isLoadingUpload}
          >
            SIMPAN PRODUK
          </Button>
          <Link to="/produk">
            <Button variant="danger">BATAL</Button>
          </Link>
        </div>
      </form>
    </FormikProvider>
  );
};

Form.propTypes = {
  initialData: {
    product_name: PropTypes.string,
    price: PropTypes.number,
    payment_plan: PropTypes.number,
    link_bonus: PropTypes.string,
    commission_amount: PropTypes.number,
    commission_type: PropTypes.string,
    image: PropTypes.string,
    status: PropTypes.string,
  },
};

Form.defaultProps = {
  initialData: {
    product_name: '',
    price: 0,
    payment_plan: 0,
    link_bonus: '',
    commission_amount: 0,
    commission_type: '',
    image: '',
    status: '',
  },
};

export default Form;
