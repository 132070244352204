import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Badge } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';
import { useQuery } from 'react-query';

import commissionEndpoint from '../../config/service/endpoint/commission';
import { Dropdown, Table } from '../../components/elements';
import { Button, InputField } from '../../components/core';
import { toRupiah } from '../../helpers';

const filterStatus = [
  { label: 'Semua Status', value: '' },
  { label: 'Sudah Dibayar', value: 'paid' },
  { label: 'Belum Dibayar', value: 'unpaid' },
];

const CommissionPage = () => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState({
    value: '',
    label: 'Semua Status',
  });

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: users, refetch } = useQuery('users-commission', () => commissionEndpoint.getAll({ page: currentPage, search: searchValue, status: status.value }), {
    onError: (error) => toast.error(error.message),
    onSuccess: () => setLoadingSearch(false),
  });

  useEffect(() => {
    refetch();
  }, [searchParams, status]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch]);

  return (
    <>
      <h4 className="mb-4">Komisi</h4>
      <div className="card">
        <div className="card-header flex-action-button d-flex align-items-center justify-content-between">
          <Dropdown
            variant="outline-primary"
            label={`Filter: ${status.label}`}
            data={filterStatus}
            onChoose={(_, item) => setStatus(item)}
          />
          <form>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
          </form>
        </div>
        <div className="card-body">
          <Table
            totalData={users?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th>Affiliate</th>
                <th>Total Komisi</th>
                <th>Status</th>
                <th>Aksi</th>
              </tr>
            )}
            bodyRender={users?.data?.clients.map((item) => (
              <tr key={item.id}>
                <td>{item.name}</td>
                <td>{toRupiah(item.amount)}</td>
                <td>
                  <Badge bg={item.status === 'paid' ? 'success' : 'warning'} className="text-white">{item.status === 'paid' ? 'Sudah Dibayar' : 'Belum Dibayar'}</Badge>
                </td>
                <td width={130} aria-label="action">
                  <Link to={`/komisi/${item.id}`}>
                    <Button size="sm" className="px-4">
                      Bayar
                    </Button>
                  </Link>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
    </>
  );
};

export default CommissionPage;
