import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { useDebounce } from '@uidotdev/usehooks';
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from 'xlsx';

import DetailModal from './components/DetailModal';
import StatusBadge from './components/StatusBadge';
import FilterDateDropdown from './components/FilterDateDropdown';
import orderEndpoint from '../../config/service/endpoint/order';
import { AlertDialog, Table } from '../../components/elements';
import { STATUS_ORDER, toRupiah } from '../../helpers';
import {
  Button, Checkbox, InputField, Select,
} from '../../components/core';

const actionOptions = [
  { value: STATUS_ORDER.COMPLETED, label: 'Selesai' },
  { value: STATUS_ORDER.CANCELED, label: 'Batal' },
];

const SalesPage = () => {
  const [detailModal, setDetailModal] = useState(null);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [actionValue, setActionValue] = useState('');
  const [isCheckAll, setCheckAll] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [openedChangeStatus, setOpenedChangeStatus] = useState(false);
  const [dateFilter, setDateFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [dateFilterExport, setDateFilterExport] = useState({
    startDate: '',
    endDate: '',
  });

  const debouncedSearch = useDebounce(searchValue, 500);
  const [searchParams] = useSearchParams();

  const currentPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  const { isFetching, data: orders, refetch } = useQuery(
    'orders',
    () => orderEndpoint.getAll({
      page: currentPage, search: searchValue, start_date: dateFilter.startDate, end_date: dateFilter.endDate,
    }),
    {
      onError: (error) => toast.error(error.message),
      onSuccess: () => setLoadingSearch(false),
    },
  );

  const { mutate: changeStatusMutate, isLoading: isLoadingChangeStatus } = useMutation(orderEndpoint.changeStatus, {
    onError: (error) => toast.error(error.message),
    onSuccess: () => {
      toast.success('Berhasil mengubah status pesanan.');
      setOpenedChangeStatus(false);
      refetch();
    },
  });

  const { isFetching: exportLoading, refetch: exportRefetch } = useQuery('export-orders', () => orderEndpoint.getAll({
    is_export: true, start_date: dateFilterExport.startDate, end_date: dateFilterExport.endDate,
  }), {
    enabled: false,
    onError: (error) => toast.error(error.message),
    onSuccess: (_data) => {
      if (_data && _data.data.length < 1) {
        toast.error('Data pesanan kosong.');
        return;
      }
      const workbook = XLSXUtils.book_new();
      const worksheet = XLSXUtils.json_to_sheet(_data.data);
      XLSXUtils.book_append_sheet(workbook, worksheet, 'Orders');
      XLSXWriteFile(workbook, 'orders.xlsx');
      toast.success('Data order berhasil diexport.');
    },
  });

  useEffect(() => {
    refetch();
    setCheckAll(false);
    setSelectedData([]);
  }, [searchParams]);

  useEffect(() => {
    refetch();
  }, [debouncedSearch, dateFilter]);

  useEffect(() => {
    setCheckAll((selectedData.length === orders?.data?.orders?.length));
  }, [selectedData]);

  const handleCheckAll = () => {
    if (isCheckAll) {
      setCheckAll(false);
      setSelectedData([]);
    } else {
      const newOrder = orders?.data?.orders.map((order) => order.id);
      setSelectedData(newOrder);
    }
  };

  const handleCheck = (id) => {
    const isExist = selectedData.some((selected) => selected === id);
    if (isExist) {
      setSelectedData((prevState) => prevState.filter((selected) => selected !== id));
    } else {
      setSelectedData((prevState) => ([
        ...prevState,
        id,
      ]));
    }
  };

  const handleApplyAction = () => {
    if (!actionValue) {
      toast.error('Pilih status terlebih dahulu.');
      return;
    }
    if (selectedData.length <= 0) {
      toast.error('Pilih minimal 1 pesanan yang akan diubah statusnya.');
      return;
    }
    setOpenedChangeStatus(true);
  };

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <h4 className="mb-0">Penjualan</h4>
        <div className="d-flex" style={{ gap: 10 }}>
          <Button
            variant="success"
            className="px-3"
            isLoading={exportLoading}
            onClick={() => exportRefetch()}
          >
            EXPORT
          </Button>
          <FilterDateDropdown
            onApply={(date) => {
              setDateFilter(date);
              setDateFilterExport(date);
            }}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-header flex-action-button d-flex align-items-center justify-content-between">
          <form className="form-inline flex-action-button" style={{ gap: 10 }}>
            <div style={{ minWidth: 130 }}>
              <Select
                placeholder="Action"
                className="w-100"
                classNameContainer="mb-0"
                data={actionOptions}
                value={actionValue}
                onChange={(event) => setActionValue(event.target.value)}
              />
            </div>
            <Button onClick={handleApplyAction}>Apply</Button>
          </form>
          <form>
            <InputField
              placeholder="Search"
              classNameContainer="mb-0"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                setLoadingSearch(true);
              }}
            />
          </form>
        </div>
        <div className="card-body">
          <Table
            totalData={orders?.data?.meta?.total}
            isLoading={isFetching || loadingSearch}
            headRender={(
              <tr>
                <th aria-label="row" width="30px">
                  <Checkbox
                    id="checkAll"
                    checked={isCheckAll}
                    onChange={handleCheckAll}
                  />
                </th>
                <th>Invoice</th>
                <th>Tanggal</th>
                <th>Pembeli</th>
                <th>Produk</th>
                <th>Affiliate</th>
                <th>Status</th>
                <th>Harga</th>
                <th>Action</th>
              </tr>
            )}
            bodyRender={orders?.data?.orders?.map((item) => (
              <tr key={item.id}>
                <td aria-label="row">
                  <Checkbox
                    id={`checkItem-${item.id}`}
                    checked={selectedData.includes(item.id)}
                    onChange={() => handleCheck(item.id)}
                  />
                </td>
                <td>{item.invoice}</td>
                <td>{moment(item.date).format('DD MMM YYYY')}</td>
                <td>{item.client_name}</td>
                <td>{item.product_name}</td>
                <td>{item.affiliate_name || '-'}</td>
                <td aria-label="status">
                  <StatusBadge status={item.status} />
                </td>
                <td>{toRupiah(item.price)}</td>
                <td width={100} aria-label="action">
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    className="px-3"
                    onClick={() => setDetailModal(item)}
                  >
                    Detail
                  </Button>
                </td>
              </tr>
            ))}
          />
        </div>
      </div>
      <DetailModal
        show={!!detailModal}
        order={detailModal}
        onClose={() => setDetailModal(null)}
      />
      <AlertDialog
        show={openedChangeStatus}
        title="Ubah Status?"
        description={`Apakah kamu yakin ingin ${actionValue === STATUS_ORDER.COMPLETED ? 'menyelesaikan' : 'membatalkan'} pesanan yang dipilih?`}
        labelCancel="Batal"
        labelConfirm="Ya, Yakin"
        isLoadingConfirm={isLoadingChangeStatus}
        onClose={() => setOpenedChangeStatus(false)}
        onConfirm={() => {
          changeStatusMutate({
            status: actionValue,
            ids: selectedData,
          });
        }}
      />
    </>
  );
};

export default SalesPage;
